import { render, staticRenderFns } from "./TermsAndConditions.vue?vue&type=template&id=58b36caa&scoped=true&"
import script from "./TermsAndConditions.vue?vue&type=script&lang=js&"
export * from "./TermsAndConditions.vue?vue&type=script&lang=js&"
import style0 from "./TermsAndConditions.vue?vue&type=style&index=0&id=58b36caa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b36caa",
  null
  
)

export default component.exports