<template>
  <div>
    <app-header />

    <p id="terms-start"></p>

    <section class="terms-and-conditions container">
      <h1>TERMS AND CONDITIONS</h1>

      <h2>Overview</h2>

      <p>
        Thank you for using our Platform and Services, as hereinafter defined,
        that connects, you <b>(“you”, “your”, “User(s)”)</b> with sellers of
        products (the <b>“Merchant(s)”</b>) and our network of independent
        delivery drivers (the <b>“Driver(s)”</b>) to facilitate on demand
        delivery of your order (the <b>“Delivery”)</b>.
      </p>

      <p>
        These terms and conditions (the <b>“Terms”</b>) are made between you and
        RoadRunner Technologies Inc., a corporation incorporated under the
        federal laws of Canada (hereinafter, <b>“ROADRUNNER, “we”, “our”</b>, or
        <b>“us”</b>). These Terms govern your use and access to our online
        shopping platform (the <b>“Platform”</b>) and our delivery services
        (collectively the <b>“Services”</b>), whether through our website,
        mobile app, or by other means.
      </p>

      <p>
        <b
          >PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
          SERVICES.</b
        >
      </p>

      <p>
        By accessing the Platform or using any of the Services, you agree to
        accept and abide by these Terms and our policies governing the use of
        the Platform and Services as may be in effect from time to time. We
        reserve the right, at our sole discretion, to update, change or replace
        any part of these Terms by posting updates and changes to the Platform.
        It is your responsibility to check periodically for changes. Your
        continued use of the Services constitutes acceptance of those changes
      </p>

      <h2>Privacy and Contact Consent</h2>

      <p>
        By accessing our Platform, you agree that we can collect and use your
        information in accordance with our privacy policies in effect from time
        to time.
      </p>

      <p>
        ROADRUNNER, Merchants and Drivers may contact you, using the information
        you have provided to us, regarding any orders you place, your use of the
        Services, communications sent to you over the Platform, updates to these
        Terms and our policies, and matters relating to your Account (as
        hereinafter defined) and you expressly consent to receive such
        communications.
      </p>

      <h2>Platform and Services</h2>

      <p>
        ROADRUNNER reserves the right, in its sole discretion, without any
        obligation and without any notice requirement, to change, discontinue
        and update the Services (including any aspect of the Platform) at any
        time, including, without limitation, to add, change or remove features
        and/or functionality and to suspend and/or deny access to the Services
        for scheduled or unscheduled maintenance, upgrades, improvements or
        corrections.
      </p>

      <p>
        Occasionally there may be information available through the Services
        that contains typographical errors, inaccuracies or omissions relating
        to product descriptions, availability, pricing, promotions, offers or
        other matters. We reserve the right to correct any errors, inaccuracies,
        or omissions, and to change or update information if any information
        related to the Services is inaccurate at any time without prior notice
        to you. ROADRUNNER reserves the right to refuse, limit or cancel any
        order you place with us. We reserve the right to limit or prohibit
        orders that, in our sole judgment, appear to be placed by dealers,
        resellers, distributors or otherwise appear to be for commercial
        purposes.
      </p>

      <h2>User Account</h2>

      <p>
        In order to access the Services, you will be required to register for
        and maintain a user account (your <b>“Account”</b>). You may only
        possess one Account at any time. As part of the process to set up your
        Account, you will be required to submit to ROADRUNNER personal
        information, such as your name, address, phone number, and payment
        information. By creating your Account, you agree to maintain accurate,
        complete, and up-to-date information in your Account.
      </p>

      <p>
        You are responsible for keeping your Account information, including your
        username and password secure and not sharing that information with
        anyone else. You are also solely responsible for keeping your devices
        secure to prevent unauthorized use and access of any passwords and
        account information. You are solely responsible for all activities that
        occur under your Account. Therefore, you should protect your password
        and make your password difficult for others to guess.
      </p>

      <p>
        ROADRUNNER has the right to suspend or terminate your Account and/or
        refuse any and all current or future use of the Services (or any portion
        thereof) if: (i) you provide, or we have reasonable grounds to believe
        you have provided, inaccurate, incomplete information or information
        that is not current; (ii) you breach these Terms; (iii) you make use of
        the Services in a manner that we consider to be objectionable for any
        reason; (iv) if your Account remains dormant for the period as
        prescribed by applicable ROADRUNNER policy; or (v) otherwise in
        accordance with the Terms or for any other reason.
      </p>

      <h2>Delivery</h2>

      <p>
        To deliver your order, we will rely on the delivery address, contact
        information, and other details/instructions provided by you (the
        <b>“Instructions”</b>). When you place an order, we may share your
        information, including but not limited to, your name, address, email and
        phone number, with the applicable Merchant and Driver.
      </p>

      <p>
        If Delivery cannot be completed due to deficiencies or errors in the
        Instructions, inability to contact you, no appropriate person available
        to receive the order, failure to provide sufficient identification when
        required or for any other reason, your order will be redirected to the
        ROADRUNNER facilities. Following a failed delivery attempt, you will be
        notified and have 72-hours to amend the Delivery Instructions and
        request re-delivery, which will be subject to additional fees relating
        to storage and re-delivery of your order.
        <b
          >You are responsible for actively requesting redelivery following a
          failed delivery.</b
        >
        If we have not received sufficient direction from you for re-delivery
        within the said 72-hours or your delivery has failed more than once
        through no fault of our own, your order may be cancelled at our sole
        discretion. Notwithstanding the foregoing, if there is a failed delivery
        of any Restricted Product, as hereinafter defined, we may cancel the
        order and return the product to the Merchant immediately or otherwise
        deal with the product in accordance with applicable regulations and our
        policies.
      </p>

      <p>
        Upon such order cancellation, we may at our discretion attempt to return
        the order to the Merchant and you will be charged for all costs
        associated therewith including, but not limited to, labour, re-stocking,
        warehousing, and delivery charges. If we are successful in returning the
        order to the Merchant and the refund exceeds the total of the associated
        costs referenced above, your Account will be credited with the
        difference. Notwithstanding the foregoing, if a refund cannot be
        obtained from the Merchant for any reason or the costs associated with
        obtaining the refund would exceed the cost of your order, in our
        opinion, you will have a further 7 days to pick up your order from our
        facilities or make other arrangements suitable to us; failing which your
        order will be forfeited and you will not be entitled to a refund or
        credit and we will have no further obligation to you in respect of same.
      </p>

      <p>
        If any product in your order has age restrictions associated with it,
        including but not limited to, alcohol, tobacco, and cannabis (each a
        <b>“Restricted Product”</b>), the recipient must provide government
        issued photo identification at the time of delivery that proves they are
        of legal age.
      </p>

      <p>
        You accept all risk of damage, loss, theft, weather exposure or other
        issues that may arise once Delivery is completed in accordance with the
        Instructions.
      </p>

      <h2>User Content</h2>

      <p>
        As part of the Services, you and other Platform users (the
        <b>“Users”</b>) may have opportunities to interact, including, without
        limitation, by posting questions, comments, product reviews, pictures or
        other information (the <b>“User Content”</b>).
      </p>

      <p>
        In submitting User Content you: (i) acknowledge and agree that any User
        Content that you provide to ROADRUNNER is non-confidential and you grant
        ROADRUNNER a worldwide, perpetual, irrevocable, transferrable,
        royalty-free license to exploit, in any manner such User Content in all
        available formats without further notice or consent from you; (ii) agree
        that ROADRUNNER will be entitled to use and disseminate User Content for
        any purpose, commercial or otherwise, without acknowledgment or
        compensation to you; (iii) grant ROADRUNNER and those we work with the
        right to use, host, store, reproduce, modify, create derivative works
        (such as translations or adaptations), publicly perform, publicly
        display, and distribute User Content; and (iv) represent and warrant
        that: (a) you own and control all of the rights to the User Content that
        you submit, or that you otherwise have the right to submit such User
        Content in connection with the use of the Services; (b) the User Content
        is accurate and not misleading; and (c) the use and posting of the User
        Content will not violate any rights of or cause injury to any person or
        entity.
      </p>

      <p>
        ROADRUNNER further reserves the right, in its sole discretion, to
        investigate, delete, remove or disable access to any User Content,
        suspend or terminate your Account at any time, or pursue any other
        remedy or relief available under equity or law if you post any User
        Content that violates these Terms or we consider to be objectionable for
        any reason.
      </p>

      <p>
        You further acknowledge that ROADRUNNER does not endorse, warrant or
        make any representations regarding any products, Merchants, or Users,
        and the User Content is not representative of the views of ROADRUNNER.
        ROADRUNNER takes no responsibility and assumes no liability for User
        Content.
      </p>

      <h2>Sales, Payments and Fees</h2>

      <p>
        By placing an order, you represent that you have reviewed and accepted
        the fees that you will be charged, which are communicated to you over
        the Platform. You agree that we, or any Third-Party Payment Processors
        (as hereinafter defined) we may use, are authorized to charge the
        applicable amount for any order you have placed, to whichever payment
        method you have provided. If we are unable to collect payment through
        the payment method you have provided or there are any abnormalities in
        the payment, we may place your order on hold, cancel your order, and/or
        withhold your access to the Services until the issue has been resolved.
      </p>

      <p>
        In the event a Driver arrives at a Merchant and finds that any ordered
        product is unavailable or there is a price discrepancy, a ROADRUNNER
        representative may contact you to inquire if you would like to approve a
        product substitution and/or price adjustment. If a product substitution
        and/or price adjustment is approved by you such adjustment will be
        reflected in your payment method and a change confirmation will be
        provided to you. If we cannot contact you or if offered product
        substitutions or price adjustments are not approved by you, we will
        remove the applicable product from your order and issue you a refund for
        such product. If applicable, we will complete the remainder of the
        order.
      </p>

      <h2>Returns, Exchanges and Cancellations</h2>

      <p>
        Return and exchange policies will be Merchant specific and will be made
        available for viewing on the Platform. Please review each applicable
        return and exchange policy prior to placing an order as such policies
        are subject to amendment from time to time.
      </p>

      <p>
        You may request a cancellation of your order at any time prior to the
        Driver collecting your order from the Merchant. Cancellation fees may
        apply. Due to communication delays, we cannot guarantee that order
        cancellation requests will be processed prior to the Driver collecting
        the order from the Merchant. Once your order has been collected from the
        Merchant by the Driver, it can no longer be cancelled, even if
        cancellation was requested prior to such collection.
      </p>

      <h2>Term and Termination</h2>

      <p>
        The Terms will remain in effect so long as is necessary to give full
        effect to your past, present and/or future use of the Services. We
        reserve the right to maintain, delete or destroy all Accounts and User
        Content in accordance with our record retention polices and applicable
        laws, without notice to you at any time for cause or as otherwise
        provided herein.
      </p>

      <h2>License and Intellectual Property</h2>

      <p>
        Subject to these Terms, ROADRUNNER hereby grants you a limited,
        revocable, non-transferable, nonsub-licensable, non-exclusive,
        royalty-free license to access and use the Services for your own
        personal and non-commercial purposes. Any rights not expressly granted
        herein are reserved by ROADRUNNER. You acknowledge and agree that the
        Services are provided under license of use only, and not for any other
        purpose. You agree that ROADRUNNER is the sole and exclusive owner of
        the Services and the Platform.
      </p>

      <p>
        All content available through the Platform (<b>“Content”</b>) including
        but not limited to trademarks, service marks, trade names, text,
        graphics, Merchant information, product information, User Content and
        code is copyrighted, and the property of ROADRUNNER, its Merchants or
        other third parties. It is protected by Canadian and international
        copyright laws. <b>ALL RIGHTS ARE RESERVED.</b>
      </p>

      <p>
        You may use the Services solely for your own use. Any and all other use
        is strictly prohibited. For greater certainty, you shall not, directly
        or indirectly: (i) in any way modify, translate, adapt or otherwise
        create derivative works of the Content; (ii) reproduce, modify, license,
        sell, resell, transfer, publicly display, broadcast, remove, delete,
        alter or obscure or otherwise exploit any Content except as expressly
        permitted by ROADRUNNER or the Content’s owner; or (iii) use the
        Platform in violation of any law.
      </p>

      <h2>Prohibited Uses</h2>

      <p>
        You are prohibited from: (a) using the Services for any unlawful purpose
        or in any manner inconsistent with the rights granted herein; (b) using
        the Services for commercial purposes; (c) targeting or communicating
        with Merchants, Drivers, ROADRUNNER or any of their affiliates for
        malicious or inappropriate purposes; (d) infringing upon or violating
        our intellectual property rights or the intellectual property rights of
        others; (e) submitting false or misleading information; (f) uploading or
        transmitting viruses or any other type of malicious code that will or
        may be used in any way to affect the functionality or operation of the
        Services or the Platform; or (g) for any reason that would violate the
        Terms. We reserve the right to terminate your Account for any breach of
        this provision.
      </p>

      <h2>International Use</h2>

      <p>
        The Services are controlled and operated from Canada. If you access the
        Platform from a location outside of Canada, you do so on your own
        initiative and are responsible for compliance with all applicable laws
        of your jurisdiction. ROADRUNNER makes no representation the Platform is
        appropriate or available for use in locations outside of Canada and
        accessing them from territories where its Content is illegal is
        prohibited. Those who choose to access the Platform from other locations
        do so on their own initiative and are responsible for compliance with
        local laws.
      </p>

      <h2>Disclaimer of Warranties</h2>

      <p>
        To the fullest extent permitted by applicable law, we exclude all
        express or implied warranties, terms and conditions including, but not
        limited to, implied warranties of quality, merchantability,
        non-infringement, fitness for a particular purpose, and those arising by
        statute or otherwise in law. ROADRUNNER is not a Merchant, manufacturer,
        wholesaler, retailer or ongoing customer service provider for anything
        ordered through the Platform.
      </p>

      <p>
        We try to keep our Services safe, secure, and functioning properly, but
        we cannot guarantee continuous or secure access to our Services or the
        Platform. ROADRUNNER therefore does not represent or warrant to you
        that: (a) your use of the Services will meet your requirements; (b) your
        use of the Services will be uninterrupted, timely, secure or free from
        error; or (c) any information obtained by you as a result of your use of
        the Services will be accurate or reliable. ROADRUNNER is not responsible
        for and disclaims all liability related to delays, delivery failures, or
        other damage resulting from matters outside of its control, including
        problems inherent in the use of the internet, mobile and personal
        computing devices and thirdparty service providers.
      </p>

      <p>
        You further agree not to hold ROADRUNNER responsible for the payment
        processing of other service providers we may utilize (each a
        <b>“Third Party Payment Processor”</b>). For greater certainty, as
        applicable, if you link a Third Party Payment Processor account to your
        Account, you acknowledge and agree that by making payments through
        ROADRUNNER with that Third Party Payment Processor, you are bound by
        that Third Party Payment Processor’s applicable terms and conditions,
        and ROADRUNNER is not liable for any loss, claims or damages howsoever
        arising in connection with such Third Party Payment Processor.
      </p>

      <h2>Limitation of Liability</h2>

      <p>
        To the fullest extent permitted by applicable law, the maximum aggregate
        liability, including but not limited to applicable legal fees and court
        costs, of ROADRUNNER or any of the other Releasees, as herein defined,
        to you or any third party (whether in contract, tort, negligence, strict
        liability in tort, by statute or otherwise) is limited to direct damages
        in an amount not to exceed the total payment you made to ROADRUNNER for
        the order giving rise to the liability.
      </p>

      <p>
        IN NO EVENT WILL ROADRUNNER OR ANY OF THE OTHER RELEASEES BE LIABLE FOR
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
        DAMAGES OF ANY TYPE OR KIND, INCLUDING PERSONAL INJURY, LOST PROFITS,
        PAIN AND SUFFERING, EMOTIONAL DISTRESS AND LOSS OF DATA, REVENUE, USE
        AND ECONOMIC ADVANTAGE (EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES) ARISING OUT OF THE USE OF THE SERVICES. THIS LIMITATION SHALL
        APPLY IRRESPECTIVE OF THE NATURE OF THE CAUSE OF ACTION, DEMAND OR
        ACTION, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, NEGLIGENCE,
        TORT OR ANY OTHER LEGAL THEORY, AND SURVIVES A FUNDAMENTAL BREACH OR
        FAILURE OF THE ESSENTIAL PURPOSE OF THE TERMS.
      </p>

      <h2>Waiver/Release</h2>

      <p>
        To the fullest extent permitted by applicable law, you hereby waive all
        rights to make or bring any claims, demands, actions, suits or
        proceedings which you have or may in the future have against us and our
        affiliates, and our and their respective officers, directors, employees
        and agents (collectively, the <b>“Releasees”</b>) and you, on your own
        behalf and on behalf of those for whom in law you are responsible,
        hereby release and forever discharge the Releasees from any and all
        claims and liability for or in respect of any personal injury, death,
        property damage, loss, cost or expense suffered or incurred as a result
        of or related to your use of the Services, due to any cause whatsoever
        including, but not limited to, negligence, willful acts or omissions,
        breach of contract or breach of any statutory or other duty of care by
        any of the Releasees or any other person.
      </p>

      <p>
        If you have a dispute with any Driver, Merchant, or other Users, you
        release ROADRUNNER (and our affiliates, and our and their respective
        officers, directors, employees, and agents) from claims, demands, and
        damages (direct actual and consequential) of every kind and nature,
        known and unknown, arising out of or in any way connected with such
        disputes.
      </p>

      <h2>Indemnity</h2>

      <p>
        You agree to indemnify, hold harmless and defend ROADRUNNER and the
        other Releasees (collectively, the <b>“Indemnified Parties”</b>) from
        and against any action, suit, claim, demand, liability, damage, loss,
        cost and/or expense (including, without limitation, legal fees) which
        may be made or brought against the Indemnified Parties or any of them or
        which any of them may suffer or incur as a result of, in respect of, or
        arising out of: (i) any breach by you of these Terms; (ii) any
        non-fulfillment by you of any covenant or agreement under these Terms;
        (iii) any breach by you, whether by commission or omission, of any of
        your obligations, representations or warranties under these Terms; (iv)
        any content you post, store or otherwise transmit on or through the
        Platform or otherwise in connection with your use of the Platform;
        and/or (iv) any violation by you of any law or rights of any third party
        in the use of the Platform.
      </p>

      <h2>General</h2>

      <p>
        These Terms and any other policies posted on the Platform or otherwise
        communicated to you constitute the entire agreement between us and you,
        superseding any prior agreements. These Terms are governed by the laws
        of the Province of Ontario and the federal laws of Canada applicable
        therein. We both submit to the jurisdiction of the courts of the
        Province of Ontario. This will not affect your statutory rights if you
        are a consumer and applicable consumer law requires application of
        another law for certain topics. If ROADRUNNER does not enforce any
        particular provision of these Terms, we are not waiving our right to do
        so later. If any part of these Terms is held invalid or unenforceable,
        that portion shall be construed in a manner consistent with applicable
        law to reflect, as nearly as possible, the original intentions of the
        parties, and the remaining portions shall remain in full force and
        effect. We may assign these Terms in our sole discretion in accordance
        with the notice provision below (our assignment to an affiliate will not
        require notice). Except for notices relating to illegal or infringing
        content, your notices to us must be sent by email to
        <a href="mailto:legal@runningtheroad.ca">legal@runningtheroad.ca</a>
      </p>

      <p>
        We shall not be liable for failure to fulfill our obligations under
        these Terms if that failure is caused, directly or indirectly, by flood,
        communications failure, road closures, internet connectivity failure,
        weather, fire, earthquake, or other natural calamity or act of God,
        interruption in electricity, acts of terrorism, riots, civil disorders,
        acts of governmental agencies, pandemics, epidemics, quarantines,
        malicious acts of third parties, labor disputes, or any other similar
        cause beyond the reasonable control of that party. During an event of
        force majeure, our obligations under these Terms, to the extent that
        they are affected by such event, will be suspended until such time as
        performance is no longer prevented or hindered.
      </p>

      <p>
        We will send notices to you via the email address you provide, or by
        registered mail. Notices sent by registered mail will be deemed received
        five days following the date of mailing. No amendment to these Terms
        will be effective unless made in writing and signed by you and by
        ROADRUNNER. Send questions, comments or complaints to
        <a href="mailto:info@runningtheroad.ca">info@runningtheroad.ca</a>
      </p>
    </section>

    <app-footer />
  </div>
</template>

<script>
  import AppHeader from '../components/RoadrunnerLanding/AppHeader'
  import AppFooter from '../components/RoadrunnerLanding/AppFooter'

  export default {
    name: 'TermsAndConditions',

    components: {
      AppHeader,
      AppFooter,
    },
  }
</script>

<style lang="scss" scoped>
  .terms-and-conditions {
    min-height: 55vh;
    padding-top: 120px;
    box-sizing: border-box;
    max-width: 850px;
    text-align: justify;
    font-family: CabinetGrotesk-Regular;

    h1 {
      text-align: center;
      margin-bottom: 45px;
    }

    h2 {
      font-size: 20px;
    }
  }
</style>
